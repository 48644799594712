import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

function handleLogin(instance, isAuthenticated) {
    if (!isAuthenticated) {
        instance.loginPopup(loginRequest).catch(e => {
            console.error(e);
        });
    } else {
        instance.logout();
    }
}

export const SignInLink = (props) => {
    const { instance } = useMsal();
    var isAuthenticated = useIsAuthenticated();
    return (
        <a className="hover:underline text-boxcut-blue" href="#" onClick={() => handleLogin(instance, isAuthenticated)} >
            {isAuthenticated ? "Logout" : "Login"}
        </a>
    );
}