import { BoxCutButton } from "../components/BoxCutButton";
import { MenuWithBody } from "../components/MenuWithBody";
import mailsend from "../media/mail-send.svg";
import { BoxCutLink } from "../components/BoxCutLink";
import React, { useState } from "react";



export default function ContactUs() {

  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errorMessages, setErrorMessages] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  let errors = [];
  //validate email input 
  // function ValidateEmail(email) {
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) { return true; }
  //   return false;
  // }
  function ValidateEmail(email) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }
  const formValidation = (event) => {
    event.preventDefault();
    setErrorMessages([]);
    const isNameValid = (name !== "");
    const isMessageValid = (message !== "");
    if (!isNameValid) {
      errors.push("Name field is empty, please try again.");
    }
    if (!ValidateEmail(email.email)) {
      errors.push("Email is not valid, please try again.");
    }
    if (email === "") {
      errors.push("Email field is empty, please try again.")
    }
    if (!isMessageValid) {
      errors.push("Message field is empty, please try again.");
    }
    if (errors.length > 0) {
      setShowErrors({ showErrors: true });
      setErrorMessages(errors);
    }
    else {
      setDisabled(true);
      setShowErrors({ showErrors: false });

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: name.name,
          phone: phone.phone,
          email: email.email,
          message: message.message
        })
      };
      fetch('https://boxcutwebbackend.azurewebsites.net/api/ContactFormEmail?code=GDHOCSD3bf4F4vz78mWoLaITOlSwyoVlLW8XhgP435OoEHdg02BlcA==', requestOptions);

      errors.push("Message Sent! We'll contact you soon.");
      setShowErrors({ showErrors: true });
      setErrorMessages(errors);
    }
  };

  return (
    <MenuWithBody>
      <div className="bg-bg-infoweb bg-no-repeat bg-cover bg-blend-darken h-[400px] flex flex-col justify-center items-center">
        <h1 className="text-boxcut-white text-6xl font-bold">contact us</h1>
        <p className="text-boxcut-white text-xl font-bold">We're excited to hear from you.</p>
      </div>
      <div className="flex ml-10 mr-10 mt-10 ">
        <div className="flex-1">
          <h1>company</h1>
          <p><a className="hover:underline text-boxcut-blue" href="https://abr.business.gov.au/ABN/View?abn=79651071699">BOXCUT TECHNOLOGIES PTY LTD</a></p>
          <p>ABN 79 651 071 699</p>
          <h1>postal</h1>
          <p>GPO Box 2592</p>
          <p>Brisbane, QLD 4001, Australia</p>
          <h1>contact</h1>
          <p><a className="hover:underline text-boxcut-blue" href="mailto:reception@boxcut-tech.com">reception@boxcut-tech.com</a></p>
        </div>
        <div className="flex-1">
          <h1>get in touch</h1>
          <form className="mt-3" action="" onSubmit={formValidation}>

            <div>
              <div className="mb-3">
                <input disabled={disabled} className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-boxcut-grey rounded-md w-full py-3 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-boxcut-orange"
                  type="text" id="name" name="name" placeholder="Your name..." onChange={e => setName({ name: e.target.value })} />
              </div>
              <div className=" mb-3">
                <input disabled={disabled} className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-boxcut-grey rounded-md w-full py-3 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-boxcut-orange"
                  type="text" id="phone" name="phone" placeholder="Phone number..." onChange={e => setPhone({ phone: e.target.value })} />
              </div>
              <div className=" mb-3">
                <input disabled={disabled} className="block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-boxcut-grey rounded-md w-full py-3 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-boxcut-orange"
                  type="text" id="email" name="email" placeholder="Email..." onChange={e => setEmail({ email: e.target.value })} />
              </div>
              <div className=" mb-3">
                <textarea disabled={disabled} className="h-32 block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-boxcut-grey rounded-md w-full py-3 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-boxcut-orange"
                  id="message" name="message" placeholder="Message.." onChange={e => setMessage({ message: e.target.value })} ></textarea>
              </div>
              <div className=" mb-3">
                <BoxCutButton disabled={disabled} icon={mailsend} type="submit" text="Submit" />
              </div>
              <div className=" mb-3">
                {showErrors ? errorMessages.map((item, index) => {
                  return <ul key={index}>{item}</ul>;
                }) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='ml-10 mt-20 mb-5'>
        <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
        <p><BoxCutLink to="/privacy" text="Privacy Policy" /></p>
      </div>
    </MenuWithBody>
  );
}