import { BoxCutLink } from "../components/BoxCutLink";
import { MenuWithBody } from "../components/MenuWithBody";

export default function OurApps() {
  return (
    <MenuWithBody>
      <div className="bg-bg-mine bg-no-repeat bg-cover bg-blend-darken h-[400px] flex flex-col justify-center items-center">
        <h1 className="text-boxcut-white text-6xl font-bold">our apps</h1>
        <p className="text-boxcut-white text-xl font-bold">Cutting edge tools.</p>
      </div>
      <div className="ml-10 mr-10 mt-10">
        <p>We are currently working on several new and exciting projects, but not everything is ready to try out. Below are a subset of our applications that we feel have the necessary polish for widespread use.</p>
        <p>You will need to be registered as a BoxCut user before you can download and access these tools. Please <BoxCutLink to="/contact-us" text="get in touch" /> and we'll set you up.</p>
        <h1><BoxCutLink text="Excel Schedule Tools" to="/our-apps/excel-schedule" /></h1>
        <p>The scheduling plugin allows you to visualise any schedule-based data in an interactive Gantt chart. If you have tasks with a start date and end date, you can visualise it
          with this tool.</p>
        <p>
          <ul>
            <li>Visualise a schedule with crisp, clean Gantt chart with custom structure, labels, tooltips and formats directly inside of Excel</li>
            <li>Print functionality that intelligently handles labelling overlaps and can be constrained to a page count</li>
            <li>Compare multiple schedules side by side - we can also show you how to draw actual linear progression vs a schedule using your machine guidance data!</li>
          </ul>
        </p>
        <h1><BoxCutLink text="Excel CAD Tools" to="/our-apps/excel-cad" /></h1>
        <p>The CAD plugin makes it easy to access the data contained within a Deswik.Duf file, loading it directly into Excel. Build your own analytical tools 
          that are directly linked to the underlying geometric data.
        </p>
        <p>
          <ul>
            <li>Retrieve geometry, property and attribute data from points, polylines and solids data directly from .duf files - no need to open CAD</li>
            <li>Import layer attributes and figure attribute data directly into Excel tables</li>
            <li>Quickly and easily import polyline vertices - great for custom haulage work</li>
            <li>Live sync - so you can automatically refresh when .duf file is updated</li>
          </ul>
        </p>
      </div>
        <div className='ml-10 mt-20 mb-5'>
              <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
              <p><BoxCutLink to="/privacy" text="Privacy Policy"/></p>
            </div>
    </MenuWithBody>
  );
}