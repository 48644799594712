import React, { useState } from "react"; 
import logo from '../media/logo.svg';
import hamburgerIcon from '../media/hamburgerIcon.svg';
import openHamburgerIcon from '../media/menu-3-line.svg';

import { Link } from "react-router-dom";
import { BoxCutButton } from './BoxCutButton';
import { SignInButton } from './SignInButton';
import { StandardNav } from "./StandardNav";

export const MenuWithBody = (props) => {
    const [ state, setState ] = useState({menu:false});

    return (
        <div className='flex flex-col h-screen overflow-hidden'>
            <div>
                <div className='flex mt-5 mb-5 ml-10 mr-10'>
                    <div className='flex-none'>
                        <Link to="/"><img style={{ height: "40px" }} src={logo} className='boxcut-logo' alt='BoxCut Technologies' /></Link>
                    </div>
                    <div className='hidden sm:block flex-none ml-4 mt-1'>
                        <StandardNav />
                    </div>
                    <div className='flex-1'>
                    </div>
                    <div className='hidden sm:block flex-none'><SignInButton /></div>
                    <div className='block sm:hidden flex-none'><BoxCutButton onClick={()=> setState({...state, menu: !state.menu})} icon={state.menu ? openHamburgerIcon : hamburgerIcon} /></div>
                </div>
            </div>
            <div className={`flex-none mb-5 ${state.menu ? "block" : "hidden"}`}>
                <StandardNav vertical={true} />
            </div>
            <div className='flex-1 overflow-y-auto'>
                {props.children}
            </div>
        </div>
    );
}