
import Toc from "../../components/toc";

export default function DCADAddin() {
    return (
      <main style={{ padding: "1rem 0" }}>
        <Toc />
        <h2>Deswik.CAD Plugin</h2>
      </main>
    );
  }