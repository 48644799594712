import { BoxCutLink } from "./BoxCutLink";
import { SignInLink } from "./SignInLink";

export const StandardNav = (props) => {
    if (props.vertical) {
        return <nav className="text-2xl text-right mr-10">
            <p><BoxCutLink text="About Us" to="/about-us" /></p>
            <p><BoxCutLink text="Our Apps" to="/our-apps" /></p>
            <p><BoxCutLink text="Contact Us" to="/contact-us" /></p>
            <p><SignInLink /></p>
        </nav>;
    }
    return <nav className="text-2xl">
        <BoxCutLink text="About Us" to="/about-us" /> |{" "}
        <BoxCutLink text="Our Apps" to="/our-apps" /> |{" "}
        <BoxCutLink text="Contact Us" to="/contact-us" />
    </nav>;
}