import { MenuWithBody } from "../../components/MenuWithBody";

import install1 from "../../media/excel-cad/msedge_UNO0xHomNp.png";
import install2 from "../../media/excel-cad/VSTOInstaller_n6uIiYcvG5.png";
import install3 from "../../media/excel-cad/VSTOInstaller_w7jwhzdARY.png";



import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BoxCutLink } from "../../components/BoxCutLink";
import { ExternalLink } from "../../components/ExternalLink";
import { SignInButton } from "../../components/SignInButton";
import { SignInLink } from "../../components/SignInLink";
import Toc from "../../components/toc";

function Install(props) {
  if (props.hasAccess) {
    return <div>
      <h1 id="install">installation</h1>
      <p>This plugin is deployed using Microsoft VSTO + ClickOnce. You'll need to use the MS Edge browser to install.</p>
      <p><ExternalLink href="https://boxcut-tech.com/cadexcelinstall/BoxCut.CadExcel.ExcelAddin.vsto">Install Plugin (Requires MS Edge)</ExternalLink></p>
      <p><img className="shadow-lg m-10" src={install1} /><img className="shadow-lg m-10" src={install2} /><img className="shadow-lg m-10" src={install3} /></p>
    </div>;
  } else {
    return <div>
      <h1 id="install">installation</h1>
      <p>This plugin is deployed using Microsoft VSTO + ClickOnce. You'll need to use the MS Edge browser to install.</p>
      <p>You don't have access to this app. Please <BoxCutLink to="/contact-us" text="contact us" /> for a free trial!</p>
    </div>;
  }
}

function Intro() {
  return <div>
    <h1 id="intro">excel cad tools</h1>
    <p>The CAD plugin makes it easy to access the data contained within a Deswik.Duf file, loading it directly into Excel. Build your own analytical tools 
          that are directly linked to the underlying geometric data.
        </p>
        <p>
          <ul>
            <li>Retrieve geometry, property and attribute data from points, polylines and solids data directly from .duf files - no need to open CAD</li>
            <li>Import layer attributes and figure attribute data directly into Excel tables</li>
            <li>Quickly and easily import polyline vertices - great for custom haulage work</li>
            <li>Live sync - so you can automatically refresh when .duf file is updated</li>
          </ul>
        </p>
  </div>;
}

function Doco(props) {
  return <div>
    <Intro />
    <Install hasAccess={props.hasAccess} />
  </div>;
}

export default function ExcelSchedule() {
  const { accounts } = useMsal();
  var hasAccess = accounts.length > 0 && accounts[0].idTokenClaims.roles.includes("Access.GHGAbatement");
  return (
    <MenuWithBody>
      <main className="mr-10 ml-10">
        <UnauthenticatedTemplate>
          <Intro />
          <h1>installation</h1>
          <p>Please <SignInLink /> to access install instructions and documentation.</p>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <div className="flex flex-row">
            <div>
              <Doco hasAccess={hasAccess} />
            </div>
            <div className="flex-none">
              <Toc />
            </div>
          </div>
        </AuthenticatedTemplate>
      <div className='mt-5 mb-5'>
        <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
      </div>
      </main>
    </MenuWithBody>
  );
}