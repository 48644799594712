import { MenuWithBody } from "../../components/MenuWithBody";

import install1 from "../../media/diesel-alternatives/msedge_psam2frCTc.png";
import install2 from "../../media/diesel-alternatives/VSTOInstaller_uMsZdJ6Ysw.png";
import install3 from "../../media/diesel-alternatives/VSTOInstaller_YQWs8NXnOp.png";

import intro1 from "../../media/schedule-tools/EXCEL_eW8cLsc61P.png";
import intro2 from "../../media/schedule-tools/EXCEL_8G8Wl4BqLj.png";
import intro3 from "../../media/schedule-tools/EXCEL_Exqvtu1a6e.png";
import additionalfields from "../../media/schedule-tools/EXCEL_H08XgHAV9Z.png";
import fielddefinitions2 from "../../media/schedule-tools/EXCEL_xwIAlRxPsC.gif";
import loadingconfig from "../../media/schedule-tools/EXCEL_OIOjI3XluE.gif";
import errorload from "../../media/schedule-tools/0J03ZLo4Yq.png";
import reload from "../../media/schedule-tools/EXCEL_zCNpzF0yvn.png";
import tasklayout from "../../media/schedule-tools/EXCEL_iffrWrgfme.gif";
import taskhighlight from "../../media/schedule-tools/EXCEL_dGkcuhJL6v.gif";
import taskother from "../../media/schedule-tools/EXCEL_DehdUjrVVS.png";
import rowlayout from "../../media/schedule-tools/EXCEL_rDsprsbCqS.gif";
import resourceMovement from "../../media/schedule-tools/EXCEL_BqIqncR0vx.png";
import print1 from "../../media/schedule-tools/EXCEL_WQZORZEr83.png";
import print2 from "../../media/schedule-tools/EXCEL_XZqgOHj5Mb.png";
import compare1 from "../../media/schedule-tools/EXCEL_umMsTGpv1h.gif";


import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BoxCutLink } from "../../components/BoxCutLink";
import { ExternalLink } from "../../components/ExternalLink";
import { SignInButton } from "../../components/SignInButton";
import { SignInLink } from "../../components/SignInLink";
import Toc from "../../components/toc";

function Install(props) {
  if (props.hasAccess) {
    return <div>
      <h1 id="install">installation</h1>
      <p>This plugin is deployed using Microsoft VSTO + ClickOnce. You'll need to use the MS Edge browser to install.</p>
      <p><ExternalLink href="https://boxcut-tech.com/ghginstall/BoxCut.Greenhouse.ExcelAddin.vsto">Install Plugin (Requires MS Edge)</ExternalLink></p>
      <p><img className="shadow-lg m-10" src={install1} /><img className="shadow-lg m-10" src={install2} /><img className="shadow-lg m-10" src={install3} /></p>
    </div>;
  } else {
    return <div>
      <h1 id="install">installation</h1>
      <p>This plugin is deployed using Microsoft VSTO + ClickOnce. You'll need to use the MS Edge browser to install.</p>
      <p>You don't have access to this app. Please <BoxCutLink to="/contact-us" text="contact us" /> for a free trial!</p>
    </div>;
  }
}

function Intro() {
  return <div>
    <h1 id="intro">ASSET REPLACEMENT AND TECHNOLOGY TRANSITION ANALYSIS</h1>
    <p>This tool takes your LOA mine schedule, Deswik LHS data, and your current asset register, and generates a schedule which contains
      a year-by-year breakdown of:</p>
    <ul className="mt-5">
      <li>Diesel Usage</li>
      <li>Electricity Usage</li>
      <li>Equipment Hours</li>
      <li>Number of Equipment</li>
      <li>Number of Replacement Equipment</li>
      <li>Replacement Equipment Costs</li>
      <li>Number of New Equipment</li>
      <li>New Equipment Costs</li>
    </ul>
    <p>This breakdown is <span className="font-bold">by asset class</span> - for example, all 400t Excavators, or all 240t Haul Trucks. The output of the analysis is a series of data tables which can fuel reports in Power BI or excel;</p>
    <p>The tool extends this data into multiple scenarios to help mine operators understand potential ways to transition to new technologies and <span className="font-bold"> reduce operational carbon emissions. </span>
      The tool is capable of analysing scenarios such as battery trucks or trolley assist. </p>
      <p> It has been specifically designed for open cut strip mines, but can also be adapted to other mining methods. <BoxCutLink to="../contact-us" text="Contact us"></BoxCutLink> for more information!</p>
    
  </div>;
}

function Doco(props) {
  return <div>
    <Intro />
    <Install hasAccess={props.hasAccess} />
  </div>;
}

export default function ExcelSchedule() {
  const { accounts } = useMsal();
  var hasAccess = accounts.length > 0 && accounts[0].idTokenClaims.roles.includes("Access.GHGAbatement");
  return (
    <MenuWithBody>
      <main className="mr-10 ml-10">
        <UnauthenticatedTemplate>
          <Intro />
          <h1>installation</h1>
          <p>Please <SignInLink /> to access install instructions and documentation.</p>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <div className="flex flex-row">
            <div>
              <Doco hasAccess={hasAccess} />
            </div>
            <div className="flex-none">
              <Toc />
            </div>
          </div>
        </AuthenticatedTemplate>
      <div className='mt-5 mb-5'>
        <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
      </div>
      </main>
    </MenuWithBody>
  );
}