import logo from '../media/logo.svg';
import hamburgerIcon from '../media/hamburgerIcon.svg';

import { Outlet, Link } from "react-router-dom";
import { BoxCutLink } from '../components/BoxCutLink';
import { BoxCutButton } from '../components/BoxCutButton';
import { SignInButton } from '../components/SignInButton';
import { MenuWithBody } from '../components/MenuWithBody';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { StandardNav } from '../components/StandardNav';

import bigX from "../media/BCT_LogoRGB-Icon.png";

function Home() {
  const { accounts } = useMsal();

  return (
    <div>
      <AuthenticatedTemplate>
        <div className='bg-bg-bigx bg-no-repeat bg-right-bottom bg-50%'>
          <MenuWithBody>
            <div className='mr-10 ml-10'>
              <h1>Welcome</h1>
              <p>Hi {accounts.length > 0 ? accounts[0].name : ''}! We're excited to have you on board.</p>
              <p>You have been assigned the following roles:</p>
              <ul>
                {accounts.length > 0 ? accounts[0].idTokenClaims.roles.map(name => (
                  <li key={name}>
                    {name}
                  </li>
                )) : ''}
              </ul>
              <p>Please open the <BoxCutLink to="our-apps" text="Our Apps"></BoxCutLink> page to access the apps available to you.</p>
              <p>Don't hesitate to get in touch with us if you have any problems getting setup.</p>
              <p>Regards,</p>
              <p>Jason &amp; Tyson.</p>
            </div>
          </MenuWithBody>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className='side-header'>
          <div className='flex flex-col h-screen ml-10 mr-10'>
            <div className='basis-full'>
              {/* Must use h-full here so that the content is vertically center aligned */}
              <div className='flex flex-col justify-center h-full text-center text-2xl'>
                <img src={logo} className='boxcut-logo' alt='BoxCut Technologies' />
                <p className='mt-7'>We are a modern, young company delivering professional consulting services and targeted software solutions to the mining industry.</p>
                <div className='mt-5'>
                  <StandardNav />
                </div>
                <div className='mt-3'>
                  <SignInButton />
                </div>
              </div>
            </div>
            <div className='mt-5 mb-5'>
              <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
              <p><BoxCutLink to="/privacy" text="Privacy Policy"/></p>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default Home;
