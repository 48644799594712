//React stuff
import React from 'react';
import ReactDOM from 'react-dom';

//React router
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

//MSAL stuff
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

//local files
import './index.css';

import Home from './routes/home';
import AboutUs from "./routes/about-us";
import Privacy from "./routes/privacy";
import ContactUs from "./routes/contact-us";
import OurApps from "./routes/our-apps";
import ExcelScheduleTools from "./routes/our-tools/excel-schedule";
import ExcelCadTools from "./routes/our-tools/excel-cad";
import DeswikCadAddin from "./routes/our-tools/dcad-addin";
import DieselAlternatives from "./routes/our-tools/diesel-alternatives";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="our-apps" element={<OurApps />} />
          <Route path="our-apps/excel-schedule" element={<ExcelScheduleTools />} />
          <Route path="our-apps/excel-cad" element={<ExcelCadTools />} />
          <Route path="our-apps/dcad-addin" element={<DeswikCadAddin />} />
          <Route path="our-apps/diesel-alternatives" element={<DieselAlternatives />} />
          <Route
            path="*"
            element={<Home />}
          />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);