import { MenuWithBody } from "../components/MenuWithBody";
import p1 from '../media/p1_15.png';
import p2 from '../media/p2_15.png';
import { BoxCutLink } from "../components/BoxCutLink";

export default function AboutUs() {
  return (
    <MenuWithBody>
      <div className="bg-bg-coal bg-no-repeat bg-cover bg-blend-darken h-[400px] flex flex-col justify-center items-center">
        <h1 className="text-boxcut-white text-6xl font-bold">about us</h1>
        <p className="text-boxcut-white text-xl font-bold">A fresh start in mining</p>
      </div>
      <div className="ml-10 mr-10 ">
        <h1>OUR TEAM</h1>
        <p>We are a passionate, tech savvy group of Mining Engineers and Software Developers that leverage cutting edge technology to build building innovative software solutions. Our technical skills and custom tools deliver efficiency and value improvements to our clients.</p>
        <p>The mining industry has a wealth of data and technology at its disposal, but mining has been slow to realise the value of that data with tools & processes that put it to use. Our goal is to modernise the mining industry.</p>        
        <h1>OUR WORK</h1>
        <p>If we have 3 days to cut down a tree, we'll spend 2 days sharpening the axe. We specialise in work that is challenging due to its size or complexity. We analyse workflows and automate them, allowing our clients to reap the benefits.</p>
        <p>We have experience with the following areas:</p>
        <ul>
          <li>Dig & Dump Reserving</li>
          <li>Custom workflows and scripts</li>
          <li>Haulage models and simulation</li>
          <li>Dragline & Dozer planning</li>
          <li>Landform reshaping and closure planning</li>
          <li>Statistical analysis and reporting</li>
          <li>Mining Performance & Data Reconciliation</li>
        </ul>
        <h1>OUR TOOLS OF CHOICE</h1>
        <h2>DESWIK SUITE</h2>
        <p>Our team members have previously worked on the Deswik suite in both consulting and software development roles, with notable projects being architecting the Deswik.Duf file format, the Road Network Design plugin & Deswik.Esri integration.</p>
        <p>We have a deep knowledge of not only how best to use the tools, but how they work under the hood, how best to automate processes, and how we can extract data.</p>
        <h2>POWER BI</h2>
        <p>Power BI is a tremendously powerful reporting tool which can make versatile visuals in hours that would have taken days via excel, with quantities of data that would simply not be possible. We build automated dashboards that unlock valuable insights into your business and free up time for your team.</p>
        <h2>EXCEL</h2>
        <p>Microsoft Excel remains a staple of the engineering world, and we’ve had more than a decade of using it to create reports, build complex models and automate work. We leverage deep technical skills and data handling best practices to ensure common rework and complexity traps are avoided. </p>
        <p>We further extend Excel's capabilities with VBA scripts for automation and data gathering via SQL. We have also developed custom Excel plugins (VSTO) – written in C# such as our <BoxCutLink text="Excel Schedule Tools" to="/our-apps/excel-schedule" /> & <BoxCutLink text="CAD Tools" to="/our-apps/excel-cad" /> plugins.</p>
        <h1>SOFTWARE DEVELOPMENT</h1>
        <p>We don't shy away from work because it's too difficult. If the tools don't exist yet, we can build them.</p>
        <p>We have a strong interest in the following areas:</p>
        <ul>
          <li>3D Simulation / Modelling / Visualisation</li>
          <li>Mine Design – Short, Medium and Long Term</li>
          <li>Artificial Intelligence / Neural Networks</li>
          <li>Big Data / Business Intelligence</li>
        </ul>
        <p>We are keen to hear your ideas and discuss solutions. This may take the form of custom bespoke solutions – tailored specifically to your requirements, or form part of an ongoing off-the-shelf product.</p>

      </div>
        <div className='ml-10 mt-20 mb-5'>
              <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
              <p><BoxCutLink to="/privacy" text="Privacy Policy"/></p>
            </div>
    </MenuWithBody>
  );
}