import { MenuWithBody } from "../../components/MenuWithBody";
import TableOfContents from "../../components/Sidebar";
import install1 from "../../media/schedule-tools/msedge_15pqtfCLC3.png";
import install2 from "../../media/schedule-tools/VSTOInstaller_ySaetQPu35.png";
import install3 from "../../media/schedule-tools/VSTOInstaller_AqAeTL2tPO.png";
import intro1 from "../../media/schedule-tools/EXCEL_eW8cLsc61P.png";
import intro2 from "../../media/schedule-tools/EXCEL_8G8Wl4BqLj.png";
import intro3 from "../../media/schedule-tools/EXCEL_Exqvtu1a6e.png";
import additionalfields from "../../media/schedule-tools/EXCEL_H08XgHAV9Z.png";
import fielddefinitions2 from "../../media/schedule-tools/EXCEL_xwIAlRxPsC.gif";
import loadingconfig from "../../media/schedule-tools/EXCEL_OIOjI3XluE.gif";
import errorload from "../../media/schedule-tools/0J03ZLo4Yq.png";
import reload from "../../media/schedule-tools/EXCEL_zCNpzF0yvn.png";
import tasklayout from "../../media/schedule-tools/EXCEL_iffrWrgfme.gif";
import taskhighlight from "../../media/schedule-tools/EXCEL_dGkcuhJL6v.gif";
import taskother from "../../media/schedule-tools/EXCEL_DehdUjrVVS.png";
import rowlayout from "../../media/schedule-tools/EXCEL_rDsprsbCqS.gif";
import resourceMovement from "../../media/schedule-tools/EXCEL_BqIqncR0vx.png";
import print1 from "../../media/schedule-tools/EXCEL_WQZORZEr83.png";
import print2 from "../../media/schedule-tools/EXCEL_XZqgOHj5Mb.png";
import compare1 from "../../media/schedule-tools/EXCEL_umMsTGpv1h.gif";


import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BoxCutLink } from "../../components/BoxCutLink";
import { ExternalLink } from "../../components/ExternalLink";
import { SignInButton } from "../../components/SignInButton";
import { SignInLink } from "../../components/SignInLink";
import Toc from "../../components/toc";

function Install(props) {
  if (props.hasAccess) {
    return <div>
      <h1 id="install">installation</h1>
      <p>This plugin is deployed using Microsoft VSTO + ClickOnce. You'll need to use the MS Edge browser to install.</p>
      <p><ExternalLink href="https://boxcut-tech.com/schedulinginstall/BoxCut.Scheduling.ExcelAddin.vsto">Install Plugin (Requires MS Edge)</ExternalLink></p>
      <p><img className="shadow-lg m-10" src={install1} /><img className="shadow-lg m-10" src={install2} /><img className="shadow-lg m-10" src={install3} /></p>
    </div>;
  } else {
    return <div>
      <h1 id="install">installation</h1>
      <p>This plugin is deployed using Microsoft VSTO + ClickOnce. You'll need to use the MS Edge browser to install.</p>
      <p>You don't have access to this app. Please <BoxCutLink to="/contact-us" text="contact us" /> for a free trial!</p>
    </div>;
  }
}

function Intro() {
  return <div>
    <h1 id="intro">boxcut excel schedule tools</h1>
    <p>The scheduling plugin allows you to visualise any schedule-based data in an interactive Gantt chart. If you have tasks with a start date and end date, you can visualise it
          with this tool.</p>
        <p>
          <ul>
            <li>Visualise a schedule with crisp, clean Gantt chart with custom structure, labels, tooltips and formats directly inside of Excel</li>
            <li>Print functionality that intelligently handles labelling overlaps and can be constrained to a page count</li>
            <li>Compare multiple schedules side by side - we can also show you how to draw actual linear progression vs a schedule using your machine guidance data!</li>
          </ul>
        </p>
    <p><img className="shadow-lg m-10" src={intro1} /></p>
  </div>;
}

function Doco(props) {
  return <div>
    <Intro />
    <Install hasAccess={props.hasAccess} />
    <h1>organising your schedule data</h1>
    <h2>input data</h2>
    <p>You'll need some schedule data to get going. This can be an export from another scheduling product (eg. Xact, Spry or Deswik) or your own custom data.</p>
    <p><img className="shadow-lg m-10" src={intro2} /></p>
    <p>
      The first step is to check your <span className="font-bold">Schedule Mapping</span>. It defines how your data maps to what is shown in the Gantt chart.
      If the drop downs are empty, the tool was unable to auto-map your data. At minimum, you'll need to specify the columns for 'Start Time' and 'End Time' for
      the tool to generate a valid Gantt chart. Fill in the other values if you have the data as well.
    </p>
    <p><img className="shadow-lg m-10" src={intro3} /></p>
    <h2>additional fields</h2>
    <p>Additional fields are columns from your data that is not core to the schedule viewer - but you may want to group on that column, or show it in a label or tooltip.</p>
    <p>In the screenshot below I've added my own column that contains a formula. I've then included this column in the schedule mapping 'Additional Fields' list.</p>
    <p><img className="shadow-lg m-10" src={additionalfields} /></p>
    <h2>field definitions</h2>
    <p>You may have some fields that contain hierarchical data. The field definitions screen allows you to define the levels in that hierarchy.</p>
    <p>Specify a separator that splits the text into its hierarchy (eg. <span className="font-bold">/ \ - _ ; :</span> or another single character), then define the label for each level of the hierarchy.</p>
    <p>You can also define colours for each unique value. This is handy if you want to colour your Gantt chart tasks using this field. Double-click on the
      value you want to specify the colour for in the 'Values' list box and select the colour you want.
    </p>
    <p><img className="shadow-lg m-10" src={fielddefinitions2} /></p>
    <p>Also, by default we sort the values alphabetically, but you can re-order this if you want. Just drag and drop values around until you have the right order.</p>
    <p>As you might have already noticed, the tool saves this data in a sheet called 'Metadata'. Feel free to edit this data directly if it's easier, just don't change the column names.</p>
    <h2>selecting the right data</h2>
    <p>You may not want to load everything into a single Gantt chart. You have a few options:</p>
    <ul>
      <li>Delete some rows from your input data sheet that you don't want</li>
      <li>Turn on the <ExternalLink href="https://support.microsoft.com/en-us/office/use-autofilter-to-filter-your-data-7d87d63e-ebd0-424b-8106-e2ab61133d92">Excel AutoFilter</ExternalLink> and setup a filter to only show the rows you want</li>
      <li>Define a loading config for the Gantt chart you want to display</li>
    </ul>
    <p>If you want to have multiple Gantt chart reports, each showing different data, you should set this up using the loading config. This
      is because any active AutoFilter on your sheet will apply to all Gantt charts in your workbook.
    </p>
    <h2>per gantt Loading Config</h2>
    <p>A loading config can be defined for each Gantt chart. You can limit data in your Gantt by:</p>
    <ul>
      <li>Tasks ending after the Start Time</li>
      <li>Tasks starting before the End Time</li>
      <li>Maximum task limit (defaults to 30,000 for performance reasons)</li>
      <li>Field filtering - specify which values to include (OR'ed)</li>
    </ul>
    <p>You can optionally choose to ignore any AutoFilter active on your data.</p>
    <p><img className="shadow-lg m-10" src={loadingconfig} /></p>
    <h1>the gantt viewer</h1>
    <h2>New Viewer</h2>
    <p>Click on 'New Viewer' to generate a new gantt chart from your schedule data. It should create a new sheet and show a basic
      un-grouped schedule with the activity as the label for your tasks.
    </p>
    <p>If your filtering is too restrictive, you may get an error:</p>
    <p><img className="shadow-lg m-10" src={errorload} /></p>
    <p>You'll need to check what filtering you've applied to fix this - check your AutoFilter on the sheet, or the Gantt Loading Config settings.</p>
    <h2>reloading data</h2>
    <p>The tasks displayed in your Gantt chart are not kept in sync with your schedule data on the sheet. Under the hood we create a copy of the data per Gantt viewer. So to update your Gantt,
      click on the 'Reload Current' button.</p>
    <p>This also means that once you create a Gantt viewer and configure it, you are free to edit, filter or delete the underlying data. That's handy if you want to just share the Gantt chart with others.</p>
    <p><img className="shadow-lg m-10" src={reload} /></p>
    <h1>Task Display</h1>
    <h2>Task Layout</h2>
    <p>It's important to group your tasks my something meaningful. Common use cases in the context of mining would be grouping
      by Resource or Location.
    </p>
    <p>This can be done inside the 'Task Layout' pane in the grouping section. You can also set a field to use for colouring, and what to display for task labels and tooltips.</p>
    <p><img className="shadow-lg m-10" src={tasklayout} /></p>
    <p>Remember - you can group by anything - just add the column to the 'Additional Fields' list in Schedule Mapping.</p>
    <p>Once your grouping is setup - users can click on groupings at any level to collapse that area.</p>
    <h2>Task Highlighting</h2>
    <p>Under the Task Display section on the ribbon, you'll see 'Highlight Colour'. This allows you to pick a colour which will be used when highlighting tasks.</p>
    <p>To highlight a task, hold down 'Ctrl' on your keyboard and click individual tasks.</p>
    <p>Note that highlighted tasks will be lost if you reload the schedule data.</p>
    <p><img className="shadow-lg m-10" src={taskhighlight} /></p>
    <h2>Other Task Settings</h2>
    <p>Don't forget that you can customise height and spacing of your task rectangles. Experiment a little to find some good settings that fit your labels.
      Don't worry about it too much - our printing logic will handle this automatically to fit your Gantt report to your desired number of pages (see below).
    </p>
    <p>You can also set a custom font for your task labels or bold them to make them easier to read.</p>
    <p><img className="shadow-lg m-10" src={taskother} /></p>
    <h1>Timeline Display</h1>
    <h2>Timeline Format</h2>
    <p>By default we have three levels - Year (yyyy), Month (MMM) and Day (dd ddd). This fits well when your 'Time Resolution' is set to 'Day'.</p>
    <p>You can update this to whatever you like - as long as its a
      recognised <ExternalLink href="https://docs.microsoft.com/en-us/dotnet/standard/base-types/custom-date-and-time-format-strings">date time to string pattern</ExternalLink></p>
    <p>We also support the following time resolutions:</p>
    <ul>
      <li>Hour</li>
      <li>Day</li>
      <li>Week</li>
      <li>Month</li>
      <li>Quarter</li>
    </ul>
    <p>You may need to tweak the 'Time Width' when changing the resolution to find the right layout.</p>
    <h2>Row layout</h2>
    <p>Mining schedules can get big. So we have some options for layout, to help increase your data density.</p>
    <ul>
      <li>One task per row - Traditional Gantt layout and least dense</li>
      <li>Inline unless labels overlap - Try to fit all tasks on the same row unless it would create problems with overlapping labels</li>
      <li>Inline unless time spans overlap - Compress all tasks onto the same row, even if labels overlap</li>
    </ul>
    <p>The tool will try its best to keep similar tasks on the same row and remove any redundant / duplicate labelling to keep things clean.
      Having shorter, easy to read labelling will also help get a good result.
    </p>
    <p><img className="shadow-lg m-10" src={rowlayout} /></p>
    <h2>resource movement</h2>
    <p>Turn on 'Resource Movement' under 'Timeline Display' to see when your asset/resource jumps between different groupings:</p>
    <p><img className="shadow-lg m-10" src={resourceMovement} /></p>
    <h1>printing</h1>
    <p>Inside the BoxCut Schedule Tools ribbon, you can configure the settings for printing your Gantt report. This includes the basics - such as paper size
      and orientation; but also 'Pages Across' and 'Pages Down'.
    </p>
    <p>'Pages Across' and 'Pages Down' settings allow you to restrict the printout to a certain number of pages. For example,
      here is the original with no page restrictions:
    </p>
    <p><img className="shadow-lg m-10" src={print1} /></p>
    <p>And here is the printout restricted to 1x1:</p>
    <p><img className="shadow-lg m-10" src={print2} /></p>
    <p>Note that you'll always get a legend at the end if you're using task colouring. If you don't need it, just skip printing that page.</p>
    <h1>Schedule Comparison</h1>
    <p>Comparing schedules for differences is super easy. All you need is a sheet with data for each schedule.</p>
    <p>Make sure you check the 'Schedule Mapping' for each sheet to make sure you are mapping the correct columns. The other settings such as 'Field Definitions' and 'Loading Config'
      is shared across all schedules.
    </p>
    <p>When comparing schedules, its important to group by the 'Source' field. This indicates which schedule the data originates from:</p>
    <p><img className="shadow-lg m-10" src={compare1} /></p>
    <p>In addition to grouping, you could set the task colour to 'Source' to see tasks from each schedule coloured differently.</p></div>;
}

export default function ExcelSchedule() {
  const { accounts } = useMsal();
  var hasAccess = accounts.length > 0 && accounts[0].idTokenClaims.roles.includes("Access.Scheduling");
  return (
    <MenuWithBody>
      <main className="mr-10 ml-10">
        <UnauthenticatedTemplate>
          <Intro />
          <h1>installation</h1>
          <p>Please <SignInLink /> to access install instructions and documentation.</p>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <div className="flex flex-row">
            <div>
              <Doco hasAccess={hasAccess} />
            </div>
            <div className="flex-none">
              <Toc />
            </div>
          </div>
        </AuthenticatedTemplate>
      <div className='mt-5 mb-5'>
        <p>Copyright © {new Date().getFullYear()} BoxCut Technologies</p>
      </div>
      </main>
    </MenuWithBody>
  );
}