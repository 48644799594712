import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { loginRequest } from "../authConfig";
import { BoxCutButton } from "./BoxCutButton";

import loginIcon from '../media/login.svg';
import logoutIcon from '../media/logout.svg';

function handleLogin(instance, isAuthenticated) {
    if (!isAuthenticated) {
        instance.loginPopup(loginRequest).catch(e => {
            console.error(e);
        });
    } else {
        instance.logout();
    }
}

export const SignInButton = () => {
    const { instance } = useMsal();
    var isAuthenticated = useIsAuthenticated();
    return (
        <BoxCutButton onClick={() => handleLogin(instance, isAuthenticated)} icon={isAuthenticated ? logoutIcon : loginIcon} text={isAuthenticated ? "Logout" : "Login"} />
    );
}